<template>
  <v-container v-if="apiLoaded">


    <div class="project-card">

      <h1>
        <span>№ {{ contract.contractNumber }} от {{ contract.startDate | formatDate }}</span>
        <v-btn @click="goEdit()">Редактировать</v-btn>
      </h1>

      <div class="badge">{{ contractStatusDict[contract.contractStatus] }}</div>

      <div class="link-tabs">
        <div>
          <router-link :to="'/contract/'+ contract.id + '/info'">Информация</router-link>
          <router-link :to="'/contract/'+ contract.id + '/stages'">Этапы</router-link>
          <router-link :to="'/contract/'+ contract.id + '/files'">Документы</router-link>
          <router-link :to="'/contract/'+ contract.id + '/additional'">Дополнительно</router-link>
          <router-link :to="'/contract/'+ contract.id + '/wires'">Проводки</router-link>
        </div>
      </div>

      <router-view :contract="contract" :container-id="contract.fileStorageContainerId" :nav-parent="'/contract/' + contract.id + '/files'"></router-view>

    </div>

  </v-container>
</template>

<script>


import {dictToObject, loadDataToObject} from "@/modules/CommonUtils";
import {contractStatusDict} from "@/modules/NSI";

export default {
  name: 'ContractComponent',
  data() {
    return {
      contract: {stages: [{}]},
      contractStatusDict: dictToObject(contractStatusDict),
      apiLoaded: false,
      apiError: false
    };
  },
  methods: {
    loadData: loadDataToObject,
    goEdit(){
      const pathItems = this.$router.currentRoute.path.split('/')
      const lastPathItem = pathItems[pathItems.length - 1]
      const newUrl = '/contractEdit/' + this.contract.id + '/#' + lastPathItem
      this.$router.push(newUrl)
    }
  },
  async beforeMount() {
    this.contractId = this.$route.params.id
    await this.loadData('/supmain/contracts/' + this.contractId, 'contract', false)
    if(this.contract.stages) {
      this.contract.stages.sort((a, b) => {
        return a.stageNumber - b.stageNumber
      })
    }
    if(this.contract.agreements) {
      this.contract.agreements.sort((a, b) => {
        return new Date(a.date) - new Date(b.date)
      })
    }
    console.log(this.contract)
    this.apiLoaded = true
  }
}
</script>

<style scoped>
.etap {
  display: flex !important;
}

.etap .summ {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  color: #2E3646;
  margin-bottom: 20px;
}

.etap .badge {
  margin-top: 25px;
}

.additional h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #2E3646;
  margin: 60px 0 20px 0;
}

.additional h2 + p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 180%;
  color: #77809A;
}

.additional .grid30 > div {
  grid-area: inherit !important;
}

.additional .grid30 > div .badge {
  margin-bottom: 0;
}
</style>
